import Headroom from "headroom.js";

// select your header or whatever element you wish
const header = document.querySelector(".header__menu--desktop");

const headroom = new Headroom(header, {
  "offset": 120,
  "tolerance": {
    up: 0,
    down: 0
  },
});
headroom.init();
